import {Injectable} from '@angular/core';
import ApiServiceClass from '../../../classes/api-service.class';
import {Poi} from '../../../interfaces/poi';
import {ConfigService} from '../../../core/services/config.service';
import {HttpClient} from '@angular/common/http';
import {PoiGroup} from '../../../interfaces/group';
import {DateTime} from 'luxon';
import {AuthService} from '../../../core/services/auth.service';
import {fromEvent, Observable} from 'rxjs';
import {auditTime, debounceTime, map, retry, startWith, switchMap, tap} from 'rxjs/operators';
import {MapService} from '../../../shared/modules/map/map.service';

export type PoiSyncType = 'vehicles' | 'drivers' | 'vehicles-groups' | 'drivers-groups';
export const POI_DAYS_COLORS = [
    {
        limit: 7,
        color: '#84CA50',
    },
    {
        limit: 30,
        color: '#F07D02',
    },
    {
        limit: 60,
        color: '#9E1313',
    },
    {
        limit: 90,
        color: '#FF0000',
    },
    {
        limit: 120,
        color: '#0E0E0F',
    }

];

@Injectable({
    providedIn: 'root'
})
export class PoiService extends ApiServiceClass<Poi> {
    defaultColor = '#777FE6';

    constructor(config: ConfigService,
                authService: AuthService,
                http: HttpClient,
                private mapService: MapService) {
        super('/poi', config, http);
    }

    updatePoiGroup(id: string, groupIds: string[]) {
        return this.http.post<PoiGroup[]>(`${this.baseUrl}/${id}/groups`, {groupIds});
    }

    syncPoi(type: PoiSyncType, id: string, poiIds: string[]) {
        return this.http.post(`${this.config.apiUrl}/${type}/${id}/pois`, {poiIds});
    }

    listPoiByType(type: PoiSyncType, id: string) {
        return this.http.get(`${this.config.apiUrl}/${type}/${id}/pois`);
    }

    addColorToPoi(poi: Poi) {
        poi.color = this.findColor(poi);
        return poi;
    }

    findColor(poi: Poi): string {
        const poiGroup = poi.groups && poi.groups.find(item => !!item.color);
        return poiGroup ? poiGroup.color : this.defaultColor;
    }

    findColorByDate(date: string) {
        if (date) {
            const diff = DateTime.fromISO(date).diffNow('days').days * -1;
            // tslint:disable-next-line: max-line-length
            return POI_DAYS_COLORS.find(limits => diff <= limits.limit || limits.limit === POI_DAYS_COLORS[POI_DAYS_COLORS.length - 1].limit).color;
        }
        return POI_DAYS_COLORS[POI_DAYS_COLORS.length - 1].color;

    }

    poiFromMapBound$(mapInstance: H.Map, params = {}): Observable<Poi[] | undefined> {
        const mapMove$ = fromEvent(mapInstance, 'mapviewchangeend');
        return mapMove$.pipe(
            startWith<any>(undefined),
            tap(console.log),
            debounceTime(550),
            switchMap(() => this.list({
                limit: 350,
                area: JSON.stringify(this.mapService.geoToGeoJsonWithFix(mapInstance.getViewModel().getLookAtData().bounds.getBoundingBox())),
                ...params
            }))
        );
    }

    recalculate(parameters) {
        return this.http.post(`${this.baseUrl}/rides/recalculate`, parameters);
    }
}
